import React, {Component} from 'react';

class Resume extends Component {
    render() {

        if (this.props.data) {
            var skillmessage = this.props.data.skillmessage;
            var education = this.props.data.education.map(function (education) {
                return <div key={education.school}><h3>{education.school}</h3>
                    <p className="info">{education.degree} <span>&bull;</span><em
                        className="date">{education.graduated}</em></p>
                    <p>{education.description}</p></div>
            })
            var work = this.props.data.work.map(function (work) {
                return <div key={work.company}><h3>{work.company}</h3>
                    <p className="info">{work.title}<span>&bull;</span> <em className="date">{work.years}</em></p>
                    <p>{work.description}</p>
                </div>
            })
            var technologies = this.props.data.technologies.map(function (technologies) {
                return <li key={technologies.name}><p className="tech"><span>&bull;</span>{technologies.name}</p></li>
            })
            var experience = this.props.data.experience.map(function (experience) {
                return <li key={experience.name}><p className="tech"><span>&bull;</span>{experience.name}</p></li>
            })
        }

        return (
            <section id="resume">

                <div className="row work">

                    <div className="three columns header-col">
                        <h1><span>Experience</span></h1>
                    </div>

                    <div className="nine columns main-col">
                        {work}
                    </div>
                </div>


                <div className="row education">
                    <div className="three columns header-col">
                        <h1><span>Education</span></h1>
                    </div>

                    <div className="nine columns main-col">
                        <div className="row item">
                            <div className="twelve columns">
                                {education}
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row skill">

                    <div className="three columns header-col">
                        <h1><span>Technologies</span></h1>
                    </div>

                    <div className="nine columns main-col">
                        <p>{skillmessage}</p>

                        <h3>DAY-TO-DAY COMFORT</h3>

                        <div className="list">
                            <ul>
                                {technologies}
                            </ul>
                        </div>
                    </div>
                    <div className="nine columns main-col">
                        <h3>EXPERIENCE WITH</h3>

                        <div className="list">
                            <ul>
                                {experience}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Resume;
